import React from 'react';
import { Link } from 'react-router-dom';

const Illustration = (props) => {
    return (
        <div className="illustration">
            <div className="illustration__title">
                {props.title}
            </div>
            <div className="illustration__image">
                <img src={props.illustration} alt="illustration" />
            </div>
            <Link to={props.link}><button className='button primary'>
              {props.trigger}</button>
            </Link>
        </div>
    );
};

export default Illustration;