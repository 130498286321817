import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import EhimLogo from '../../resources/assets/images/ehimlogo.png';
import { getCertificates, logout, switchCertificate } from '../../redux/actions/auth';
import Loader from './Loader';
import { compose } from 'redux';
import { Link, withRouter } from 'react-router-dom';
import lang from '../../resources/lang/lang.json';

const Header = (props) => {
    const [opened, openMenu] = useState(false);

    const { getCertificates, auth, certificates, selectedCert } = props;
    useEffect(() => {
        getCertificates(auth);
    }, [getCertificates, auth])

    if (selectedCert !== null)
        return (<>
            {opened && <div className='overlay' onClick={() => openMenu(false)}></div>}
            <div className='header'>
                <div className='header__content'>
                  <Link to='/'>  <div className='logo'>

                        <img src={EhimLogo} alt='logo' />
                    </div></Link>
                    <div className='selectCompany' onClick={() => openMenu(!opened)} >
                        {selectedCert.structureData?.structureName}
                    </div>
                    {opened && <DropDownMennu certificates={certificates.length > 1 ? certificates : []} logout={props.logout}
                        switchCertificate={props.switchCertificate} history={props.history} />}
                </div>
            </div>
        </>
        );
    else
        return <Loader />
};

const DropDownMennu = (props) => {

    return <>

        <div className='dropDownMenun'>
            {props.certificates.map((cert, i) => <div key={i} className='menu-item'
                onClick={() => props.switchCertificate(cert.certificateNumber, () => props.history.push('/main'))}>
                {cert.structureData?.structureName}</div>)}
            <hr />
            <div onClick={props.logout} className='menu-item'>{lang.logout}</div>
        </div></>
}


let mapStateToProps = state => {
    return {
        selectedCert: state.auth.selectedCert,
        auth: state.auth.authenticated,
        certificates: state.auth.certificates,
    }
}
export default compose(withRouter, connect(mapStateToProps, { getCertificates, logout, switchCertificate }))(Header);