import { GET_CERTS, GET_TOKEN, IS_LOADING_AUTH, IS_LOADING_CERTS, IS_REGISTRAION_LOADING, SET_CERTIFICATE } from "../actions/types";

const initialState = {
    authenticated: localStorage.getItem('token'),
    isLoading: null,
    certificates: null,
    isLoadingCerts: true,
    selectedCert: null,
    isRegistrationLoading: true
}

export const authReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_TOKEN:
            return { ...state, authenticated: action.payload };
        case IS_LOADING_AUTH:
            return { ...state, isLoading: action.payload }
        case GET_CERTS:
            return { ...state, certificates: action.payload }
        case SET_CERTIFICATE:
            return { ...state, selectedCert: action.payload }
        case IS_LOADING_CERTS:
            return { ...state, isLoadingCerts: action.payload }
        case IS_REGISTRAION_LOADING:
            return { ...state, isRegistrationLoading: action.payload }
        default:
            return state;
    }
}