import React from 'react';
import { NavLink } from 'react-router-dom';
import projectIcon from '../../../resources/assets/images/projectIcon.svg';
import basicIcon from '../../../resources/assets/images/basicIcon.svg';
import proposalIcon from '../../../resources/assets/images/proposalIcon.svg';
import lang from '../../../resources/lang/lang.json';

const NavBar = (props) => {
    return (
        <div className='navbar'>
            <NavLink to='/main' exact activeClassName='active'> <img src={basicIcon} alt="basic" className='icon' />
                <span> {lang.companyDescription}</span></NavLink>
            <NavLink to='/main/proposals' activeClassName='active'> <img src={proposalIcon} alt="proposal" className='icon' />
                <span> {lang.requests}</span></NavLink>
            <NavLink to='/main/projects' activeClassName='active'>
                <img src={projectIcon} alt="projects" className='icon' />
                <span> {lang.projects}</span></NavLink>
        </div>
    );
};

export default NavBar;