import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { compose } from 'redux';
import { getCertificates } from '../../redux/actions/auth';
import ErrorPage from '../common/ErrorPage';
import Loader from '../common/Loader';
import authRequired from '../HOC/authRequired';
import lang from '../../resources/lang/lang.json';

const Certificates = (props) => {

    const { getCertificates, auth, certificates, isLoadingCerts } = props;
    useEffect(() => {
        getCertificates(auth);
    }, [getCertificates, auth]);

    if (isLoadingCerts)
        return <Loader />
    if (certificates?.length === 1)
        return <Redirect to='/main' />
    if (certificates?.length > 1)
        return <Redirect to='/entry' />
    if (certificates?.length === 0 || !certificates )
        return <ErrorPage msg={lang.youHaveNoRights} logout />


};


const mapStateToProps = state => {
    return {
        auth: state.auth.authenticated,
        certificates: state.auth.certificates,
        isLoadingCerts: state.auth.isLoadingCerts,
    }
}
export default compose(
    authRequired,
    connect(mapStateToProps, { getCertificates }),
)(Certificates);