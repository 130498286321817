import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { ToastContainer, Zoom } from 'react-toastify';
import NotFound from './components/common/NotFound';
import LoginContainer from './components/pages/auth/LoginContainer';
import SwitchCertificates from './components/pages/auth/SwitchCertificates';
import Certificates from './components/pages/Certificates';
import CompanyContainer from './components/pages/CompanyContainer';
import './resources/assets/sass/index.scss';

const App = () => {
  return (
    <div>
      <Switch>
        <Route path='/' exact component={Certificates} />
        <Route path='/login' component={LoginContainer} />
        <Route path='/entry' component={SwitchCertificates} />
        <Route path='/main' component={CompanyContainer} />
        <Route component={NotFound} />
      </Switch>
      <ToastContainer />
    </div>
  );
}

export default App;
