import { Form, Formik } from 'formik';
import React, { useEffect } from 'react';
import FormikControl from '../../common/formControl/FormikControl';
import lang from '../../../resources/lang/lang.json';

const ProjectsForm = (props) => {


    const { getData, inputValues } = props;

    useEffect(() => {
        getData();
    }, [getData])
    const initialValues = {
        projects: [
            { name: '', start_date: null, partners: '', initial_investment: '' },
            { name: '', start_date: null, partners: '', initial_investment: '' },
            { name: '', start_date: null, partners: '', initial_investment: '' },
        ]
    }
    const onSubmit = (values) => {
        let cb = () => props.setActiveIndex(props.activeIndex + 1);
        props.handleSubmit(values, cb);
    }

    let projectsOptions = [{ key: 'name', label: lang.project, placeholder: lang.projectName },
    { key: 'start_date', control: 'date', autoComplete: "off", label: lang.project_start_date, placeholder: lang.project_start_date },
    { key: 'partners', label: lang.projectPartners, placeholder: lang.projectPartners },
    { key: 'initial_investment', label: lang.initial_investment, placeholder: lang.initial_investment_ph },]
    return (
        <div className="content__form">
            <Formik
                initialValues={(inputValues.projects?.length > 0 && inputValues) || initialValues}
                onSubmit={onSubmit}
                enableReinitialize
            // validationSchema={validationSchema}
            > 
                {
                    formik => (
                        <Form >
                            <FormikControl control='array' name='projects' options={projectsOptions} formclass='m-1-auto' initialValues={initialValues.projects} />
                            <div className="action-btn">
                                <input type="button" className="button " value='Geri' onClick={() => props.setActiveIndex(props.activeIndex - 1)} />
                                <input type="submit" className="button primary" disabled={!formik.isValid || formik.isSubmitting} value='İrəli' />
                            </div>
                        </Form>)

                }
            </Formik>
        </div>
    );
};

export default ProjectsForm;