import { errorHandler } from "../../common/errorHandler";
import { baseURL } from "./baseUrl"
import { CLEAR_PROPOSAL_DATA, CLEAR_SENDED_PROPOSAL, GET_PROJECTS_LIST, GET_PROPOSALS, IS_UPLOAD_FILES, PROJECT_SCOPES_LIST, PROPOSAL_IS_LOADING, PROPOSAL_SENDED, SET_PROPOSAL_DATA, UPLOADED_FILES } from "./types";
import fileDownload from 'js-file-download'



export const getProjectsList = () => dispatch => {
    baseURL.get('/list/projects')
        .then((response) => {
            let result = response.data.data;
            dispatch({
                type: GET_PROJECTS_LIST,
                payload: result
            })
        })
        .catch(err => errorHandler(err))
}

export const sendProposalProject = (params, goBack) => dispatch => {
    baseURL.post('/product/request', params)
        .then((response) => {
            goBack();
        })
        .catch(err => errorHandler(err))
}

export const uploadFiles = (formData) => dispatch => {
    dispatch({ type: IS_UPLOAD_FILES, isLoading: true })
    baseURL.post('/requests/request_information/upload', formData)
        .then((response) => {
            let result = response.data.data;
            dispatch({
                type: UPLOADED_FILES,
                payload: result
            })
        })
        .catch(err => errorHandler(err))
        .finally(() => dispatch({ type: IS_UPLOAD_FILES, isLoading: false }))
}
export const clearUploadedFiles = () => dispatch => dispatch({ type: UPLOADED_FILES, payload: [] })

export const downloadFile = (fileName) => dispatch => {
    baseURL.get(`/requests/request_information/download/${fileName}`,{
        responseType: 'blob',
    })
        .then((res) =>   fileDownload(res.data, fileName))
}


export const sendProposal = (data) => dispatch => {
    baseURL.post('/requests/request_information', data)
        .then(response => {
            let result = response.data.data;
            dispatch({
                type: PROPOSAL_SENDED,
                payload: result
            })
        })
        .catch(err => errorHandler(err))
}

export const getProjectScopesList = () => dispatch => {
    baseURL.get('/list/project_scopes')
        .then(response => {
            let result = response.data.data;
            dispatch({
                type: PROJECT_SCOPES_LIST,
                payload: result
            })
        })
        .catch(err => errorHandler(err))
}

export const clearSendedProposal = () => dispatch => {
    dispatch({ type: CLEAR_SENDED_PROPOSAL })
}

export const getProposals = () => dispatch => {
    dispatch({ type: PROPOSAL_IS_LOADING, payload: true })
    baseURL.get('/requests/request_information')
        .then(response => {
            let result = response.data.data;
            dispatch({
                type: GET_PROPOSALS,
                payload: result
            })
        })
        .catch(err => errorHandler(err))
        .finally(() => dispatch({ type: PROPOSAL_IS_LOADING, payload: false }))
}

export const getProposalById = (id) => dispatch => {
    baseURL.get(`/requests/request_information/${id}`)
        .then(response => {
            let result = response.data.data;
            dispatch({
                type: SET_PROPOSAL_DATA,
                payload: result
            })
        }).catch(err => errorHandler(err));
}

export const clearProposalData = () => dispatch => dispatch({ type: CLEAR_PROPOSAL_DATA })