import React from 'react';
import { ErrorMessage, Field } from 'formik';
import TextError from './TextError';


const Select = props => {
    const { label, name, options, ...rest } = props;
    return (
        <div className={`form-control ${props.formclass ? props.formclass : ''}`}>
            {label && <label htmlFor={name} className={props.labelclass ? props.labelclass : ''}>{label}</label>}
            <Field as='select' id={name} name={name} {...rest}
                className={`form-input ${props.className ? props.className : ''}`}>
                {
                    options.map((option,i) => {
                        return (
                            <option key={i} value={option.id}>
                                {option.label}
                            </option>
                        )
                    })
                }
            </Field>
            <ErrorMessage name={name} component={TextError} />
        </div>
    )
}

export default Select;