import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import illustration from '../../../../../resources/assets/images/illustration.svg';
import lang from '../../../../../resources/lang/lang.json';
import Illustration from './Illustration';
import { getProposals } from "../../../../../redux/actions/proposal";
import Loader from '../../../../common/Loader';
import ListProposal from './ListProposal';

const Proposals = (props) => {
    const { getProposals } = props;
    useEffect(() => {
        getProposals()
    }, [getProposals])
 
    if (props.proposalsLoading)
        return <div className='proposal-page'><Loader size='medium'/></div>
    if (props.proposals.length === 0)
        return (
            <Illustration
                title={lang.youHaveNoProposal}
                link={'/main/proposals/new'}
                illustration={illustration}
                trigger={<><span>+</span> {lang.newProposal}</>}
            />);
    return <ListProposal proposals={props.proposals}/>
};
let mapStateToProps = state => {
    return {
        proposals: state.proposal.proposals,
        proposalsLoading: state.proposal.proposalsLoading
    }
}
export default connect(mapStateToProps, { getProposals })(Proposals);