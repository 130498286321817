import { toast } from "react-toastify";
import lang from '../resources/lang/lang.json';
import 'react-toastify/dist/ReactToastify.css';
import { toastError } from "./config";

export const errorHandler = (error = {}) => {
    if (error.response?.status === 401)
        window.location.replace('/login')
    else if (error.response?.data?.exception?.message)
        toast.error(error.response?.data?.exception?.message, toastError)
    else
        toast.error(lang.mainError, toastError)
}
