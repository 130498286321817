import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import Header from '../common/Header';
import RegisterCompany from './registration/RegisterContainer';
import { checkRegistration } from "../../redux/actions/registration";
import { logout } from '../../redux/actions/auth';
import Loader from '../common/Loader';
import AccountContainer from './account/AccountContainer';
import { compose } from 'redux';
import authRequired from '../HOC/authRequired';
import ErrorPage from '../common/ErrorPage';
import lang from '../../resources/lang/lang.json';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const CompanyContainer = (props) => {
    const { checkRegistration, selectedCert, isRegistrationLoading } = props;
    useEffect(() => {
        checkRegistration();
    }, [checkRegistration, selectedCert])
    if (!isRegistrationLoading) {
        if (process.env.REACT_APP_FAKE_USER_CERT.split(' ').includes(selectedCert.selectedCertNumber)) //FAKE USER
            return <RegisteredCompany isRegistered={props.isRegistered} />;


        if ((selectedCert.selectedCertNumber && selectedCert.structureData?.legal
            && selectedCert.structureData?.hasStamp))
            return <RegisteredCompany isRegistered={props.isRegistered} />;
        else
            return <ErrorPage msg={lang.youHaveNoRights} logout />
    }
    else
        return <Loader />
};
let mapsStateToProps = state => ({
    isRegistrationLoading: state.auth.isRegistrationLoading,
    selectedCert: state.auth.selectedCert,
    isRegistered: state.registration.isRegistered
})

export default compose(authRequired, connect(mapsStateToProps, { checkRegistration, logout }))(CompanyContainer);


const RegisteredCompany = (props) => {
    return <div className='main'>
        <Header />
        <div className='content'>
            {
                (() => {
                    if (props.isRegistered)
                        return <AccountContainer />
                    if (props.isRegistered === 0)
                        return <RegisterCompany />
                    else
                        return <Loader />
                })()
            }
        </div>

    </div>
}