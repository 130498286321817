import axios from "axios";
import { errorHandler } from "../../common/errorHandler";
import { baseURL } from "./baseUrl";
import {
    ANNUAL_TURNOVERS_LIST, CERTS_LIST, IS_REGISTERED, IS_REGISTRAION_LOADING, PARTNERS_LIST, SET_ACTIVITIES_INFO,
    SET_BASIC_INFO, SET_HR_INFO, SET_INFRASTRUCTURE_INFO, SET_PROJECTS_INFO
} from "./types";


export const checkRegistration = () => dispatch => {
    dispatch({ type: IS_REGISTRAION_LOADING, payload: true })
    baseURL.get('/organizations/registered_organization')
        .then(response => {
            let result = response.data.data;
            dispatch({
                type: IS_REGISTERED,
                payload: result
            })
        })
        // .catch(err => errorHandler(err))
        .finally(() => dispatch({ type: IS_REGISTRAION_LOADING, payload: false }))
}

export const setBasicInfo = (data, nextSection = function () { }) => (dispatch, getState) => {
    let exist = getState().registration.basicInfo;
    let method = exist ? 'put' : 'post';
    baseURL[method]('/organizations/basic_info', data)
        .then(response => {
            let result = response.data.data;
            dispatch({
                type: SET_BASIC_INFO,
                payload: result
            })
            nextSection();

        })
        .catch(err => errorHandler(err))
}

export const getBasicInfo = () => dispatch => {
    baseURL.get('/organizations/basic_info')
        .then(response => {
            let result = response.data.data;
            dispatch({ type: SET_BASIC_INFO, payload: result })
        })
        .catch(err => errorHandler(err))
}

export const setCompanyActivities = (data, nextSection = function () { }) => (dispatch, getState) => {
    let exist = getState().registration.activitiesInfo;
    let method = exist ? 'put' : 'post';
    baseURL[method]('/organizations/activities_info', data)
        .then(response => {
            let result = response.data.data;
            dispatch({
                type: SET_ACTIVITIES_INFO,
                payload: result
            })
            nextSection();
        })
        .catch(err => errorHandler(err))
}
export const getCompanyActivities = () => dispatch => {
    baseURL.get('/organizations/activities_info')
        .then(response => {
            let result = response.data.data;
            dispatch({
                type: SET_ACTIVITIES_INFO,
                payload: result
            })
        })
        .catch(err => errorHandler(err))
}

export const getActivitiesLists = () => dispatch => {
    let annualTurnover = baseURL.get('/list/annual_turnovers');
    let certs = baseURL.get('/list/cert_types');
    let partners = baseURL.get('/list/partner_types');
    axios.all([annualTurnover, certs, partners])
        .then(response => {
            dispatch({ type: ANNUAL_TURNOVERS_LIST, payload: response[0].data.data })
            dispatch({ type: CERTS_LIST, payload: response[1].data.data })
            dispatch({ type: PARTNERS_LIST, payload: response[2].data.data })
        })
        .catch(err => errorHandler(err))
}

export const setProjectInfo = (data, nextSection) => (dispatch, getState) => {
    let exist = getState().registration.projectsInfo.projects.length > 0;
    let method = exist ? 'put' : 'post';
    baseURL[method]('/organizations/project_info', data)
        .then(response => {
            let result = response.data.data;
            dispatch({
                type: SET_PROJECTS_INFO,
                payload: result
            })
            nextSection();
        })
        .catch(err => errorHandler(err))
}

export const getProjectInfo = () => dispatch => {
    baseURL.get('/organizations/project_info')
        .then(response => {
            let result = response.data.data;
            dispatch({
                type: SET_PROJECTS_INFO,
                payload: result
            })
        })
        .catch(err => errorHandler(err))
}

export const setHRInfo = (data, nextSection) => (dispatch, getState) => {
    let exist = getState().registration.HRInfo;
    let method = exist ? 'put' : 'post';
    baseURL[method]('/organizations/hr_info', data)
        .then(response => {
            let result = response.data.data;
            dispatch({
                type: SET_HR_INFO,
                payload: result
            })
            nextSection();
        })
        .catch(err => errorHandler(err))
}
export const getHRInfo = () => dispatch => {
    baseURL.get('/organizations/hr_info')
        .then(response => {
            let result = response.data.data;
            dispatch({
                type: SET_HR_INFO,
                payload: result
            })
        })
        .catch(err => errorHandler(err))
}


export const setInfrastructureInfo = (data, callback = function () { }) => (dispatch, getState) => {
    let exist = getState().registration.infrastructureInfo;
    let method = exist ? 'put' : 'post';
    baseURL[method]('/organizations/infrastructure_info', data)
        .then(response => {
            let result = response.data.data;
            dispatch({
                type: SET_INFRASTRUCTURE_INFO,
                payload: result
            })
            dispatch(checkRegistration());
            callback();
        })
        .catch(err => errorHandler(err))
}

export const getInfrastructureInfo = () => dispatch => {
    baseURL.get('/organizations/infrastructure_info')
        .then(response => {
            let result = response.data.data;
            dispatch({
                type: SET_INFRASTRUCTURE_INFO,
                payload: result
            })
        })
        .catch(err => errorHandler(err))
}