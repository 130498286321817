import { combineReducers } from "redux";
import { authReducer } from "./authReducer";
import { registrationReducer } from "./registrationReducer";
import { proposalReducer } from './proposalReducer';


const rootReducer = combineReducers({
    auth: authReducer,
    registration: registrationReducer,
    proposal: proposalReducer,
})

export default rootReducer;