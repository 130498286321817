import {
    ANNUAL_TURNOVERS_LIST, CERTS_LIST, IS_REGISTERED, PARTNERS_LIST, SET_ACTIVITIES_INFO,
    SET_BASIC_INFO, SET_HR_INFO, SET_INFRASTRUCTURE_INFO, SET_PROJECTS_INFO
} from "../actions/types";


let initialValues = {
    basicInfo: null,
    activitiesInfo: null,
    annualTurnoverList: [],
    certsList: [],
    partnersList: [],
    projectsInfo: { projects: [] },
    HRInfo: null,
    infrastructureInfo: null,
    createdAt: null
}
export const registrationReducer = (state = initialValues, action) => {
    switch (action.type) {
        case IS_REGISTERED:
            return {
                ...state,
                isRegistered: action.payload ? action.payload.is_show : 0,
            }

        case SET_BASIC_INFO:
            return {
                ...state,
                basicInfo: action.payload
            }
        case SET_ACTIVITIES_INFO:
            return {
                ...state,
                activitiesInfo: action.payload
            }
        case ANNUAL_TURNOVERS_LIST:
            return {
                ...state,
                annualTurnoverList: action.payload
            }
        case CERTS_LIST:
            return {
                ...state,
                certsList: action.payload
            }
        case PARTNERS_LIST:
            return {
                ...state,
                partnersList: action.payload
            }
        case SET_PROJECTS_INFO:
            return {
                ...state,
                projectsInfo: { projects: action.payload }
            }
        case SET_HR_INFO:
            return {
                ...state,
                HRInfo: action.payload
            }
        case SET_INFRASTRUCTURE_INFO:
            return {
                ...state,
                infrastructureInfo: action.payload
            }
        default:
            return state;
    }
}