import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { getProjectsList } from '../../../../redux/actions/proposal';

const Projects = (props) => {
    const { getProjectsList, projectList } = props;
    useEffect(() => {
        getProjectsList();
    }, [getProjectsList])
    return (
        <div className='content-box'>
            {
                projectList.map(project => <Link key={project.id} to={`/main/projects/${project.id}`}><div className='box'>
                    <img  alt={project.image} src={`/static/images/${project.image}.svg`} onError={(e)=>e.target.src='/static/images/EHIM-3.svg'} />
                </div></Link>)
            }

        </div>
    );
};
let mapStateToProps = state => {
    return {
        projectList: state.proposal.projectList
    }
}
export default connect(mapStateToProps, { getProjectsList })(Projects);