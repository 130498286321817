import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { checkToken } from '../../redux/actions/auth';
import Loader from '../common/Loader';

let authRequired = (ChildComponent) => {
    class ComposedComponent extends React.Component {
        componentDidMount() {
            this.props.checkToken(this.props.auth)
        }
        componentDidUpdate() {
            this.shouldNavigateToLogin();
        }

        shouldNavigateToLogin() {
            if (!this.props.auth && !this.props.isLoading)
                this.props.history.push('/login');
        }
        render() {
            return this.props.isLoading === false ? <ChildComponent /> : <Loader />
        }
    }
    function mapsStateToProps(state) {
        return { auth: state.auth.authenticated, isLoading: state.auth.isLoading };
    }
    return compose(
        connect(mapsStateToProps, { checkToken }),
        withRouter
    )(ComposedComponent);
}
export default authRequired;