import { Form, Formik } from 'formik';
import React, { useEffect } from 'react';
import FormikControl from '../../common/formControl/FormikControl';
import * as Yup from 'yup';
import lang from '../../../resources/lang/lang.json';
import { mobileNumberFormat } from '../../../common/helpers';

const BasicInfoForm = (props) => {

    const { getData, inputValues } = props;
    useEffect(() => {
        getData();
    }, [getData])
    let initialValues = {
        responsible_person: '',
        position: '',
        email: '',
        office_phone_number: '',
        company_director: '',
        structure_name: '',
        post_index: '',
        mobile_number: '',
        address: ''
    };

    let validationSchema = Yup.object({
        responsible_person: Yup.string().required(lang.required),
        structure_name: Yup.string().required(lang.required),
        email: Yup.string().email(lang.invalidEmail).required(lang.required),
        company_director: Yup.string().required(lang.required),
        mobile_number: Yup.string().min(9,lang.invalidNumber).required(lang.required),
        address: Yup.string().required(lang.required),
        position: Yup.string().required(lang.required),
        office_phone_number:Yup.string().min(9,lang.invalidNumber)
    });

    const onSubmit = (values) => {
        let cb = () => props.setActiveIndex(props.activeIndex + 1);
        props.handleSubmit(values, cb);

    }
    return (
        <div className="content__form">
            <Formik
                initialValues={inputValues || initialValues}
                onSubmit={onSubmit}
                validationSchema={validationSchema}
                enableReinitialize
            >
                {
                    formik => (
                        <Form >
                            <div className='form-inline'>
                                <FormikControl control='input' name='responsible_person'
                                    label={lang.responsible_person} placeholder={lang.responsible_person} />
                                <FormikControl control='input' name='position'
                                    label={lang.position} placeholder={lang.position} />


                                <FormikControl control='input' name='email'
                                    label={lang.email} placeholder={lang.email} />

                                <FormikControl control='number' name='mobile_number' isAllowed={mobileNumberFormat}
                                    format='+994 (##) ###-##-##' label={lang.mobile_number} />

                            </div>
                            <hr className='hr' />
                            <div className='form-inline'>

                                <FormikControl control='input' name='company_director'
                                    label={lang.company_director} placeholder={lang.company_director} />
                                <FormikControl control='input' name='post_index'
                                    label={lang.post_index} placeholder={lang.post_index} />
                                <FormikControl control='input' name='structure_name'
                                    label={lang.structure_name} placeholder={lang.structure_name} />
                                <FormikControl control='number' format='+994 (##) ###-##-##' name='office_phone_number'
                                    label={lang.office_phone_number}  />
                                <FormikControl control='textarea' name='address' className='addressScale'
                                    label={lang.address} placeholder={lang.justAddress}  />
                            </div>
                            <div className="action-btn">
                                <input type="submit" className="button primary" disabled={!formik.isValid || formik.isSubmitting} value='İrəli' />
                            </div>
                        </Form>)

                }
            </Formik>
        </div>
    );
};

export default BasicInfoForm;