import React from 'react';
import { connect } from 'react-redux';
import { logout } from "../../redux/actions/auth";
import lang from '../../resources/lang/lang.json';

const ErrorPage = (props) => {
    return (
        <div className='errorPage'>
            <div >{props.msg}</div>
            {props.logout && <button className="button primary" onClick={() => props.logout()}>{lang.logout}</button>}
        </div>
    );
};

export default connect(null, { logout })(ErrorPage);