import { Form, Formik } from 'formik';
import React, { useEffect } from 'react';
import FormikControl from '../../common/formControl/FormikControl';
import * as Yup from 'yup';
import lang from '../../../resources/lang/lang.json';

const HRForm = (props) => {
    const { getData, inputValues } = props;

    useEffect(() => {
        getData();
    }, [getData])


    const validationSchema = Yup.object({ 
        certified_employees_count: Yup.number().max(Yup.ref('employees_count'), lang.validationNoMoreThanOrEqual).min(0, lang.mustBePositiveNumber),
        service_contract_count: Yup.number().test({
            test: function () {
                const { employment_contract_count, employees_count, service_contract_count } = this.parent;
                let diff = checkNumb(employees_count) - (checkNumb(service_contract_count) + checkNumb(employment_contract_count));
                if (diff < 0)
                    return this.createError({ message: lang.validationNoMoreThan })
                return true
            }
        }).min(0, lang.mustBePositiveNumber),
        employment_contract_count: Yup.number().test({
            test: function () {
                const { employment_contract_count, employees_count, service_contract_count } = this.parent;
                let diff = checkNumb(employees_count) - (checkNumb(service_contract_count) + checkNumb(employment_contract_count));
                if (diff < 0)
                    return this.createError({ message: lang.validationNoMoreThan })
                return true
            }
        }).min(0, lang.mustBePositiveNumber),
        software_team_count: Yup.number().min(0, lang.mustBePositiveNumber),
        it_infrastructure_team_count: Yup.number().min(0, lang.mustBePositiveNumber),
        cybersecurity_team_count: Yup.number().min(0, lang.mustBePositiveNumber),
        pm_team_count: Yup.number().min(0, lang.mustBePositiveNumber),
        business_analytics_team_count: Yup.number().min(0, lang.mustBePositiveNumber),
        marketing_team_count: Yup.number().min(0, lang.mustBePositiveNumber),
        administrative_staff_count: Yup.number().min(0, lang.mustBePositiveNumber),
        testing_team_count: Yup.number().min(0, lang.mustBePositiveNumber),
        support_team_count: Yup.number().min(0, lang.mustBePositiveNumber),
    })
    const initialValues = {
        employees_count: 0,
        service_contract_count: '',
        employment_contract_count: '',
        certified_employees_count: '',
        software_team_count: '',
        it_infrastructure_team_count: '',
        cybersecurity_team_count: '',
        pm_team_count: '',
        business_analytics_team_count: '',
        marketing_team_count: '',
        administrative_staff_count: '',
        testing_team_count: '',
        support_team_count: '',
    }
    const onSubmit = (values) => {
        let cb = () => props.setActiveIndex(props.activeIndex + 1);
        props.handleSubmit(values, cb);

    }
    const checkNumb = (numb) => (numb) ? parseInt(numb) : 0;

    const autoIncrementCount = ({ values }) => {
        return values.employees_count = (checkNumb(values.software_team_count) + checkNumb(values.it_infrastructure_team_count)
            + checkNumb(values.cybersecurity_team_count) + checkNumb(values.pm_team_count)
            + checkNumb(values.business_analytics_team_count) + checkNumb(values.marketing_team_count) + checkNumb(values.administrative_staff_count)
            + checkNumb(values.testing_team_count) + checkNumb(values.support_team_count));
    }
    return (
        <div className="content__form">
            <Formik
                initialValues={inputValues || initialValues}
                onSubmit={onSubmit}
                validationSchema={validationSchema}
                enableReinitialize
            >
                {
                    formik => (
                        <Form >

                            <div className='form-inline'>
                                <FormikControl control='input' type='number' name='software_team_count'
                                    label={lang.software_team_count} placeholder='0-50' />
                                <FormikControl control='input' type='number' name='it_infrastructure_team_count'
                                    label={lang.it_infrastructure_team_count} placeholder='0-50' />
                                <FormikControl control='input' type='number' name='cybersecurity_team_count'
                                    label={lang.cybersecurity_team_count} placeholder='0-50' />
                                <FormikControl control='input' type='number' name='pm_team_count'
                                    label={lang.pm_team_count} placeholder='0-50' />
                                <FormikControl control='input' type='number' name='business_analytics_team_count'
                                    label={lang.business_analytics_team_count} placeholder='0-50' />
                                <FormikControl control='input' type='number' name='marketing_team_count'
                                    label={lang.marketing_team_count} placeholder='0-50' />
                                <FormikControl control='input' type='number' name='administrative_staff_count'
                                    label={lang.administrative_staff_count} placeholder='0-50' />
                                <FormikControl control='input' type='number' name='testing_team_count'
                                    label={lang.testing_team_count} placeholder='0-50' />
                                <FormikControl control='input' type='number' name='support_team_count'
                                    label={lang.support_team_count} placeholder='0-50' />
                            </div>
                            <hr className='hr' />
                            <div className='form-inline'>
                                <FormikControl control='input' type='number' name='service_contract_count'
                                    label={lang.service_contract_count} placeholder='0-50' />
                                <FormikControl control='input' type='number' name='employment_contract_count'
                                    label={lang.employment_contract_count} placeholder='0-50' />
                                <FormikControl control='input' type='number' name='certified_employees_count'
                                    label={lang.certified_employees_count} placeholder='0-50' />
                                <FormikControl control='input' type='number' name='employees_count' disabled value={autoIncrementCount(formik)}
                                    label={lang.employees_count} placeholder='0' />
                            </div>
                            <div className="action-btn">
                                <input type="button" className="button " value='Geri' onClick={() => props.setActiveIndex(props.activeIndex - 1)} />
                                <input type="submit" className="button primary " disabled={!formik.isValid || formik.isSubmitting} value='İrəli' />
                            </div>
                        </Form>)

                }
            </Formik>
        </div>
    );
};

export default HRForm;