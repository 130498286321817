import { Form, Formik } from 'formik';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import FormikControl from '../../../common/formControl/FormikControl';
import { sendProposalProject } from '../../../../redux/actions/proposal';
import BackButton from '../../../common/BackButton';
import lang from '../../../../resources/lang/lang.json';
import illustrationDone from "../../../../resources/assets/images/illustrationDone.svg";

import * as Yup from 'yup';
import Illustration from './proposal/Illustration';

const Project = (props) => {
    const [projectForm, isProjectForm] = useState(true);
    let initialValues = {
        fullname: '',
        email: '',
        phone: ''
    }
    let validationSchema = Yup.object({
        email: Yup.string().email(lang.invalidEmail).required(lang.required),
        fullname: Yup.string().required(lang.required),
        phone: Yup.string().required(lang.required),
    })
    let onSubmit = (values) => {
        let id = parseInt(props.match.params.id);
        let params = { ...values, project_id: id };
        let cb = () => isProjectForm(false);
        props.sendProposalProject(params, cb);
    }
    return (
        projectForm ? <><BackButton title="Yeni müraciət" />
            <div className='content'>
                <Formik
                    initialValues={initialValues}
                    onSubmit={onSubmit}
                    enableReinitialize
                    validationSchema={validationSchema}
                >
                    {formik => <Form>
                        <div className="account__form">
                            <div className='inline-form'>
                                <FormikControl control='input' name='fullname' label={lang.fullname} placeholder={lang.fullname} />
                                <FormikControl control='input' name='email' label={lang.emailExtra} placeholder={lang.email} formclass='w-auto' />
                                <FormikControl control='number' name='phone' label={lang.phone} placeholder={lang.phone} format='+994 (##) ###-##-##' />
                            </div>
                            <input type="submit" className="button primary btn-right" disabled={!formik.isValid || formik.isSubmitting} value={lang.acceptProposal} />
                        </div>
                    </Form>}
                </Formik>
            </div> </> :
            <Illustration
                title={lang.yourProposalWasAccepted}
                link={'/main/projects/'}
                illustration={illustrationDone}
                trigger={lang.projects}
            />

    );
};

export default connect(null, { sendProposalProject })(Project);