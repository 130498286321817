import { Form, Formik, FieldArray } from 'formik';
import React, { useEffect, useState } from 'react';
import FormikControl from '../../common/formControl/FormikControl';
import lang from '../../../resources/lang/lang.json';
import * as Yup from 'yup';

const CompanyActivities = (props) => {
    const [open, setOpen] = useState(false);
    const [openW, setOpenW] = useState(false);

    const { getData, inputValues, getLists, extraData } = props;

    useEffect(() => {
        getData();
        getLists();
    }, [getData, getLists])

    let initialValues = {
        start_date: null,
        annual_turnover_id: 1,
        profit_lost_result: '',
        audit_report_recieve_address: '',
        doneWorks: [{ equipment: '', software: '' }],
        partners: [{ name: '', partner_type_id: 1 }],
        certificates: [{ name: '', certificate_type_id: 1 }],

    }
    let validationSchema = Yup.object({

    })
    const onSubmit = (values) => {
        let cb = () => props.setActiveIndex(props.activeIndex + 1);
        props.handleSubmit(values, cb);
    }


    let doneWorksOptions = [{ key: 'equipment', label: lang.equipment, placeholder: lang.equipment },
    { key: 'software', label: lang.software, placeholder: lang.software }]

    let annualTurnoverOptions = extraData.annualTurnoverList;
    let currentPartnersOptions = extraData.partnersList;
    let orgCertificatesOptions = extraData.certsList;

    return (
        <div className="content__form">
            <Formik
                initialValues={inputValues || initialValues}
                onSubmit={onSubmit}
                enableReinitialize
                validationSchema={validationSchema}
            >
                {
                    formik => (
                        <Form >
                            <div className='form-inline'>

                                <FormikControl control='date' name='start_date'
                                    label={lang.start_date} />
                                <FormikControl control='select' name='annual_turnover_id'
                                    label={lang.annual_turnover_id} options={annualTurnoverOptions} />
                                <FormikControl control='input' name='profit_lost_result'
                                    label={lang.profit_lost_result} placeholder='Vəzifəsi' />
                                <FormikControl control='textarea' name='audit_report_recieve_address' className='addressScale'
                                    label={lang.audit_report_recieve_address} placeholder='Ünvanı' />

                            </div>

                            <FormikControl control='array' name='doneWorks' initialValues={initialValues.doneWorks}
                                label={lang.doneWorks} options={doneWorksOptions} />

                            <label className='block-title'>{lang.currentPartners}</label>
                            <FieldArray name='partners'>
                                {
                                    (fieldArrayProps) => {
                                        const { push, form, remove } = fieldArrayProps;
                                        let partners = form.values?.partners;
                                        return <>
                                            <div className='form-inline'>
                                                {partners?.map((partner, index) => {
                                                    let label = currentPartnersOptions.find(opt => opt.id === partner.partner_type_id)?.name;
                                                    return <div key={index}>
                                                        <FormikControl control={'input'} name={`partners[${index}].name`}
                                                            label={label} placeholder={label} />
                                                    </div>
                                                })}
                                                {/* {partners?.length > form.initialValues.partners.length ? <button type='button' className='button primary icon' onClick={() => remove(partners?.length - 1)}> - </button> : ''} */}
                                                {partners?.length > initialValues.partners.length? <button type='button' className='button primary icon' onClick={() => remove(partners?.length - 1)}> - </button> : ''}
                                                <button type='button' className='button primary icon' onClick={() => setOpen(!open)}> + </button>
                                                {open ? <div className='dropdown-array-btn'>{currentPartnersOptions.map((opt, i) => <div key={i}
                                                    onClick={() => { push({ name: '', partner_type_id: opt.id }); setOpen(!open) }}>{opt.name}</div>)}</div> : ''}
                                            </div>
                                        </>
                                    }
                                }
                            </FieldArray>
                            <label className='block-title'>{lang.orgCertificates}</label>
                            <FieldArray name='certificates'>
                                {
                                    (fieldArrayProps) => {
                                        const { push, form, remove } = fieldArrayProps;
                                        let certificates = form.values?.certificates;
                                        return <>
                                            <div className='form-inline'>
                                                {certificates?.map((cert, index) => {
                                                    let label = orgCertificatesOptions?.find(opt => opt.id === cert.certificate_type_id)?.name;
                                                    return <div key={index}>
                                                        <FormikControl control={'input'} name={`certificates[${index}].name`}
                                                            label={label} placeholder={label} />
                                                    </div>
                                                })}
                                                {certificates?.length  > initialValues.certificates.length  ? <button type='button' className='button primary icon' onClick={() => remove(certificates?.length - 1)}> - </button> : ''}
                                                <button type='button' className='button primary icon' onClick={() => setOpenW(!openW)}> + </button>
                                                {openW ? <div className='dropdown-array-btn'>{orgCertificatesOptions?.map((opt, i) => <div key={i}
                                                    onClick={() => { push({ name: '', certificate_type_id: opt.id }); setOpenW(!openW) }}>{opt.name}</div>)}</div> : ''}
                                            </div>
                                        </>
                                    }
                                }
                            </FieldArray>

                            <div className="action-btn">
                                <input type="button" className="button " value='Geri' onClick={() => props.setActiveIndex(props.activeIndex - 1)} />
                                <input type="submit" className="button primary " disabled={!formik.isValid || formik.isSubmitting} value='İrəli' />
                            </div>
                        </Form>)

                }
            </Formik>
        </div>
    );
};

export default CompanyActivities;