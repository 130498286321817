
import loader from "../../resources/assets/images/loader.svg";

const Loader = (props) => {
    switch (props.size) {
        case 'tiny':

            return <div className='loader'>
                <img src={loader} alt="loader" width='50px' />
            </div>;
        case 'mini':

            return <div className='loader'>
                <img src={loader} alt="loader" width='100px' />
            </div>;
        case 'medium':

            return <div className='loader'>
                <img src={loader} alt="loader" width='150px' />
            </div>;

        default:
            return (
                <div className='loader'>
                    <img src={loader} alt="loader" />
                </div>
            );
    }

}

export default Loader;