import React, { useState } from 'react';
import ReactDom from 'react-dom';

const Modal = (props) => {
    const [modal, showModal] = useState(false);
    let onDismiss = () => showModal(false)
    let onSubmit = () => {
        onDismiss();
        props.submit();
    }
    return <><div onClick={() => showModal(true)}> {props.trigger}</div>
        {  (modal) && ReactDom.createPortal(<div className='modals' onClick={onDismiss}>
            <div className='modal' onClick={(e) => e.stopPropagation()}>
                {props.title && <div className="header">
                    <span>{props.title}</span>
                    <span className='x' onClick={onDismiss}></span>
                </div>}
                <div className="content">{props.children}</div>
                <div className="actions">
                    {props.negativeValue && <input type="button" className="button black" value={props.negativeValue} disabled={props.disabled} onClick={onDismiss} />}
                    {props.positiveValue && <input type="submit" className="button success" value={props.positiveValue} onClick={onSubmit} disabled={props.disabled} />}
                </div>
            </div>
        </div>, document.querySelector('#modal')
        )}  </>
};

export default Modal;