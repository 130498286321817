import React from 'react'
import { Field, ErrorMessage } from 'formik'
import TextError from './TextError'

function RadioButtons(props) {
  const { label, name, options, ...rest } = props
  return (
    <div className={`form-control form-control--inline justify-start ${props.formclass ? props.formclass : ''}`}>
      <label className='radio-label'>{label}</label>
      <Field name={name} >
        {({ field }) => {
          return options.map(option => {
            return (
              <React.Fragment key={option.key}>
                <label className='checkbox-label'  >
                  <input
                    type='radio'
                    id={option.value}
                    {...field}
                    {...rest}
                    className={`radio ${props.fieldClass ? props.fieldClass : ''}`}
                    value={option.value}
                    checked={field.value === option.value}
                  />
                  {option.key}</label>
              </React.Fragment>
            )
          })
        }}
      </Field>
      <ErrorMessage component={TextError} name={name} />
    </div>
  )
}

export default RadioButtons