import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { asanLogin, checkToken } from '../../../redux/actions/auth';
import asanLoginLogo from '../../../resources/assets/images/EHIM-3.svg';
import asanLoginBtn from "../../../resources/assets/images/AsanLogin-BTN.svg";
import lang from '../../../resources/lang/lang.json';

const LoginContainer = (props) => {

    const { auth, history, asanLogin } = props;

    useEffect(() => {
        if (auth) {
            history.push('/')
        }
    }, [auth, history])
    return (
        <div className='asan-login'>
            <div className='asan-login__block'>


                <div className='logo'>
                    <img src={asanLoginLogo} alt='' />

                </div>

                <img className='asan-login__block--btn' src={asanLoginBtn} alt="Asan Login" onClick={asanLogin} />

                <div className='bottom-title'>
                    <div>version 0.0.1</div>
                    <div><span>&#169;</span> {lang.eGovRoleInProject}</div>
                </div>
            </div>
        </div>
    );
};
const mapStateToProps = state => {
    return {
        auth: state.auth.authenticated
    }
}
export default connect(mapStateToProps, { asanLogin, checkToken })(LoginContainer);