import { ErrorMessage, Field } from 'formik';
import React from 'react';
import TextError from './TextError';


const Input = props => {
    const { label, name, ...rest } = props;
    return (
        <div className={`form-control ${props.formclass ? props.formclass : ''}`}>
            {label && <label htmlFor={name}>{label}</label>}
            <Field>
                {({ form }) => <Field id={name} name={name} {...rest}  maxLength={60}
                    className={`form-input ${props.fieldClass ? props.fieldClass : ''} ${(form.errors[name] && form.touched[name]) ?
                         'error-border':''}`} />
                }
            </Field>
            <ErrorMessage name={name} component={TextError} />
        </div>
    )
}

export default Input;