import React, { useEffect, useState } from 'react';
import BackButton from '../../../../common/BackButton';
import { Form, Formik } from 'formik';
import FormikControl from '../../../../common/formControl/FormikControl';
import lang from '../../../../../resources/lang/lang.json';
import { connect } from 'react-redux';
import { sendProposal, getProjectScopesList, clearSendedProposal, getProposalById, clearProposalData } from "../../../../../redux/actions/proposal";
import { getBasicInfo } from "../../../../../redux/actions/registration";
import UploadFiles from './UploadFiles';
import Illustration from './Illustration';
import illustrationDone from "../../../../../resources/assets/images/illustrationDone.svg";
import * as Yup from 'yup';
import ShowUploadedFiles from './ShowUploadedFiles';
import Modal from '../../../../common/Modal';

const FormProposal = (props) => {
    const { getProjectScopesList, clearSendedProposal, basicInfo, clearProposalData,
        getBasicInfo, getProposalById } = props;
    let readable = props.match.params.id ? props.match.params.id : false;
    // const [modal, approveModal] = useState(false)

    useEffect(() => {
        if (readable)
            getProposalById(readable)

        getBasicInfo();
        getProjectScopesList();
        return () => {
            clearSendedProposal();
            clearProposalData()
        }
    }, [getProjectScopesList, clearSendedProposal, clearProposalData, getBasicInfo, readable, getProposalById])

    let initialValues = {
        structureName: props.structureName,
        presentedOrganizationDate: new Date(),
        projectStartDate: null,
        responsible_person: basicInfo ? basicInfo.responsible_person : '',
        position: basicInfo ? basicInfo.position : '',
        mobile_number: basicInfo ? basicInfo.mobile_number : '',
        email: basicInfo ? basicInfo.email : '',

        project_name: '',
        project_scope_id: 1,
        project_inpmlementation_period_year: 0,
        project_inpmlementation_period_month: 0,
        project_benefits: '',
        project_total_budget: '',
        project_description: '',
        project_goals: '',
        project_stages: '',
        project_role_egov: '',
        country_policy_relation: '',
        request_activities: [{
            activity_description: '', start_date: null, end_date: null,
            responsible_person_position: '', notes: ''
        }],
    };
    const validationSchema = Yup.object({
        project_name: Yup.string().required(lang.required),
        project_benefits: Yup.string().required(lang.required),
        project_total_budget: Yup.string().required(lang.required),
        project_description: Yup.string().test({
            test: function (value) {
                let wordsCount = 250; // minimum words count
                let count = (value?.split(/\s+/gi).length - 1);
                if (count <= wordsCount)//countin words and display
                    return this.createError({ message: `${lang.min} ${wordsCount - count} ${lang.mustBeWords}` });
                return true
            }
        }).required(lang.required),
        project_goals: Yup.string().required(lang.required),
        project_stages: Yup.string().required(lang.required),
        project_role_egov: Yup.string().required(lang.required),
        country_policy_relation: Yup.string().required(lang.required),
        project_inpmlementation_period_month: Yup.number().test({
            test: function () {
                const { project_inpmlementation_period_year, project_inpmlementation_period_month } = this.parent;
                let sum = project_inpmlementation_period_year + project_inpmlementation_period_month;
                if (sum <= 0)
                    return this.createError({ message: lang.required })
                return true
            }
        }),
        request_activities: Yup.array()
            .of(
                Yup.object().shape({
                    activity_description: Yup.string().required(lang.required),
                    responsible_person_position: Yup.string().required(lang.required),
                    end_date: Yup.date().required(lang.required).nullable(),
                    start_date: Yup.date().required(lang.required).nullable(),
                    notes: Yup.string().required(lang.required),
                })
            ),

    })
    const onSubmit = (values) => {
        // values.request_files = Array.isArray
        //     (props.uploadedFiles) ? props.uploadedFiles : [];
        values.request_files = initialValues.request_files;
        props.sendProposal(values);
    }
    let getUploadedFiles = (value) => {
        if (value)
            initialValues.request_files = value;
        else
            initialValues.request_files = [];
    }
    let projectScopesList = props.projectScopesList.map(scope => ({ id: scope.id, label: scope.name }));
    let activityOptions = [
        { key: 'activity_description', label: lang.activitiesDescription, placeholder: lang.activitiesDescription },
        { key: 'start_date', control: 'date', label: lang.startDate, placeholder: lang.startDate },
        { key: 'end_date', control: 'date', minDate: 'start_date', label: lang.endDate, placeholder: lang.endDate },
        { key: 'responsible_person_position', label: lang.positionResponsiblePerson, placeholder: lang.positionResponsiblePerson },
        { key: 'notes', control: 'textarea', label: lang.notes, placeholder: lang.notes }
    ];

    let projectInpmlementationPeriod = {
        year: [{ id: 0, label: 0 }, { id: 1, label: 1 }, { id: 2, label: 2 }, { id: 3, label: 3 }, { id: 4, label: 4 }, { id: 5, label: 5 }],
        month: [{ id: 0, label: 0 }, { id: 1, label: 1 }, { id: 2, label: 2 }, { id: 3, label: 3 }, { id: 4, label: 4 }, { id: 5, label: 5 }, { id: 6, label: 6 }, { id: 7, label: 7 }, { id: 8, label: 8 }, { id: 9, label: 9 }, { id: 10, label: 10 }, { id: 11, label: 11 }]
    }

    return (
        !props.proposalSended ? <div>
            <BackButton title={readable ? "Müraciət" : "Yeni müraciət"} />
            <div className="content">
                <div className="content__form proposal__form">

                    <Formik
                        initialValues={props.proposalData || initialValues}
                        enableReinitialize
                        onSubmit={onSubmit}
                        validationSchema={validationSchema}
                    >
                        {formik => <Form >
                            <label className='block-title'>{lang.proposalInfo}</label>
                            <div className='form-inline'>
                                <FormikControl control='input' name='structureName'
                                    label={lang.structureName} disabled />
                                <FormikControl control='date' name='presentedOrganizationDate'
                                    label={lang.presentedOrganizationDate} disabled />
                                <FormikControl control='date' name='projectStartDate' formclass='w-auto'
                                    label={lang.projectStartDate} placeholder={lang.notDecided} disabled={readable} />
                            </div>
                            <hr className='hr  m-0' />
                            <label className='block-title'>{lang.responsibleProjectperson}</label>
                            <div className='form-inline'>

                                <FormikControl control='input' name='responsible_person'
                                    label={lang.fullName} disabled />
                                <FormikControl control='input' name='position'
                                    label={lang.position} disabled />
                                <FormikControl control='input' name='mobile_number'
                                    label={lang.mobileNumber} disabled />
                                <FormikControl control='input' name='email'
                                    label={lang.email} disabled />
                            </div>
                            <hr className='hr  m-0' />
                            <label className='block-title'>{lang.basicInfo}</label>
                            <div className='form-inline'>
                                <FormikControl control='input' name='project_name' disabled={readable}
                                    label={lang.projectName} placeholder={lang.projectName} />
                                <FormikControl control='select' name='project_scope_id' options={projectScopesList}
                                    label={lang.projectScope} placeholder={lang.projectScope} disabled={readable} />
                                <div className='form-control w-auto mt-0'>
                                    <label className="label">{lang.projectImplementationPeriod}</label>
                                    <div className='d-flex'>
                                        <FormikControl control='select' name='project_inpmlementation_period_year' formclass='w-auto form-control--inline m-0 mr-10 mt-10' disabled={readable}
                                            label={lang.year} options={projectInpmlementationPeriod.year} labelclass='mr-10' />
                                        <FormikControl control='select' name='project_inpmlementation_period_month' formclass='w-auto form-control--inline m-0 mr-10 mt-10' disabled={readable}
                                            className='mr-10' label={lang.month} options={projectInpmlementationPeriod.month} labelclass='mr-10' />
                                    </div>
                                </div>
                                <FormikControl control='input' name='project_benefits' formclass='w-auto' disabled={readable}
                                    label={lang.projectMainBenefits} placeholder={lang.projectMainBenefits} />
                                <FormikControl control='input' name='project_total_budget' formclass='w-auto' disabled={readable}
                                    label={lang.projectTotalBudget} placeholder={lang.projectTotalBudget} />
                            </div>
                            <hr className='hr  m-0' />
                            <label className='block-title'>{lang.summaryActivitiesResults}</label>
                            <div className="proposal-block">
                                <label className='proposal-block__title'>{lang.whoAndHowWillBeAchieve}</label>
                                <FormikControl control='textarea' name='project_description' formclass='w-auto' className='infoScale'
                                    label={lang.projectSummary} placeholder={lang.text} disabled={readable} />
                            </div>
                            <div className="proposal-block">
                                <label className='proposal-block__title'>{lang.goalsToAchieve}</label>
                                <FormikControl control='textarea' name='project_goals' formclass='w-auto' className='infoScale'
                                    label={lang.projectGoals} placeholder={lang.text} disabled={readable} />
                            </div>
                            <div className="proposal-block">
                                <label className='proposal-block__title'>{lang.indicateTheMainElementOfEachStage}</label>
                                <FormikControl control='textarea' name='project_stages' formclass='w-auto' className='infoScale'
                                    label={lang.projectStages} placeholder={lang.text} disabled={readable} />
                            </div>
                            <div className="proposal-block">
                                <label className='proposal-block__title'>{lang.describeDetailAxpectations}</label>
                                <FormikControl control='textarea' name='project_role_egov' formclass='w-auto' className='infoScale'
                                    label={lang.eGovRoleInProject} placeholder={lang.text} disabled={readable} />
                            </div>
                            <hr className='hr m-0' />
                            <div className='form-inline'>
                                <FormikControl control='array' name='request_activities' options={activityOptions} label={lang.activitiesDrutingImplementation}
                                    btn={lang.add} btnclass={'proposal__btn'} disableremove disabled={readable} closeBtn />
                            </div>
                            <div className="proposal-block">
                                <label className='proposal-block__title'>{lang.countryPolicyReconciliation}</label>
                                <FormikControl control='textarea' name='country_policy_relation' formclass='w-auto' className='infoScale'
                                    label={lang.statePolicyServiceProvideReferences} placeholder={lang.text} disabled={readable} />
                            </div>
                            {!readable ? <><label className='block-title'>{lang.budget}</label>
                                <div className="proposal-block">
                                    <UploadFiles getUploadedFiles={getUploadedFiles} />
                                </div></> :
                                <><label className='block-title'>{lang.budget}</label>
                                    <div className="proposal-block">
                                        <ShowUploadedFiles data={props.proposalData} />
                                    </div></>
                            }
                            {!readable && <Modal
                                children={<h2>{lang.sureToApprove}</h2>}
                                submit={formik.submitForm}
                                disabled={formik.isSubmitting}
                                negativeValue={lang.no}
                                positiveValue={lang.yes}
                                trigger={<input type="button" className="button primary float-right" disabled={!formik.isValid || formik.isSubmitting}
                                    value={lang.registerProposal} />}
                            />}
                        </Form>}
                    </Formik>
                </div>
            </div>
        </div> : <Illustration
            title={lang.yourProposalWasAccepted}
            link={'/main/proposals/'}
            illustration={illustrationDone}
            trigger={lang.proposals}
        />
    );
};
let mapStateToProps = state => {
    return {
        proposalSended: state.proposal.proposalSended,
        projectScopesList: state.proposal.projectScopesList,
        structureName: state.auth.selectedCert.structureData.structureName,
        basicInfo: state.registration.basicInfo,
        proposalData: state.proposal.proposalData
    }
}

export default connect(mapStateToProps, { getProposalById, clearProposalData, sendProposal, getProjectScopesList, clearSendedProposal, getBasicInfo })(FormProposal);
