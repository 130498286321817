import React from 'react'
import DateView from 'react-datepicker'
import { Field, ErrorMessage } from 'formik'
import TextError from './TextError'
import 'react-datepicker/dist/react-datepicker.css'

function DatePicker(props) {
    const { label, name, ...rest } = props
    return (
        <div className={`form-control ${props.formclass ? props.formclass : ''}`}>
            {label && <label htmlFor={name}>{label}</label>}
            <Field name={name} >
                {({ form, field }) => {
                    const { setFieldValue } = form
                    const { value } = field
                    return (
                        <DateView
                            id={name}
                            {...field}
                            {...rest}
                            autoComplete='off'
                            dateFormat='MM/dd/yyyy'
                            minDate={props.mindate && props.mindate}
                            value={value ? Date.parse(value) : null}
                            placeholderText={props.placeholder ? props.placeholder : 'dd/mm/yyyy'}
                            selected={value ? new Date(value) : value}
                            className={`form-input ${props.className ? props.className : ''}`}
                            onChange={val => setFieldValue(name, val)}
                        />
                    )
                }}
            </Field>
            <ErrorMessage component={TextError} name={name} />
        </div>
    )
}

export default DatePicker