import React from 'react'
import { Field, ErrorMessage } from 'formik'
import TextError from './TextError'

function Textarea (props) {
  const { label, name, ...rest } = props
  return (
    <div className={`form-control ${props.formclass?props.formclass:''}`}>
      <label htmlFor={name}>{label}</label>

      <Field>
                {({ form }) =>  <Field as='textarea' id={name} name={name} {...rest} 
                className={`form-input ${props.className ? props.className : ''}
                        ${(form.errors[name] && form.touched[name]) && 'error-border'}`} />
                }
            </Field>
     
      <ErrorMessage component={TextError} name={name} />
    </div>
  )
}

export default Textarea