import { Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import FormikControl from '../../common/formControl/FormikControl';
import lang from '../../../resources/lang/lang.json';

const InfrastructureForm = (props) => {
    const [isAuditReported, toggleAudit] = useState(false);

    const { getData, inputValues } = props;

    useEffect(() => {
        getData();
        toggleAudit(inputValues?.last_audit_date === null);

    }, [inputValues?.last_audit_date, getData])

    const initialValues = {
        servers_info: '',
        last_audit_date: null,
        security_risk_exist: null,
        previous_projects_tech_docs: null
    }
    const onSubmit = (values) => {
        if (values.security_risk_exist)
            values.security_risk_exist = values.security_risk_exist ? parseInt(values.security_risk_exist) : values.security_risk_exist;
        if (values.previous_projects_tech_docs)
            values.previous_projects_tech_docs = values.previous_projects_tech_docs ? parseInt(values.previous_projects_tech_docs) : values.previous_projects_tech_docs;
        if (isAuditReported)
            values.last_audit_date = isAuditReported ? null : values.last_audit_date;

        let cb = () => props.history.push('/main');
        props.handleSubmit(values, cb);
    }
    const cyberRadioOptions = [{ key: 'Hə', value: '1' }, { key: 'Yox', value: '0' }];
    const previousProjectoptions = [{ key: 'Hə', value: '1' }, { key: 'Yox', value: '0' }];
    return (
        <div className="content__form">
            <Formik
                initialValues={inputValues || initialValues}
                onSubmit={onSubmit}
                enableReinitialize
                // validationSchema={validationSchema}
            >
                {
                    formik => (
                        <Form >
                            <FormikControl control='textarea' name='servers_info' formclass='w-auto'
                                className='infoScale' label={lang.servers_info} placeholder={lang.text} />
                            <div className='form-inline'>
                                <FormikControl control='date' name='last_audit_date' disabled={isAuditReported} formclass='w-auto' className='w-100'
                                    label={lang.last_audit_date} />
                                <div className={`form-control form-control--inline w-auto m-t align-center`}>
                                    <input type="checkbox" id="auditToggle" name="auditToggle" className='checkbox' checked={isAuditReported}
                                        onChange={() => toggleAudit(!isAuditReported)} />
                                    <label htmlFor="auditToggle" className='checkbox-label'>{lang.auditToggle}</label>
                                </div>
                            </div>
                            <FormikControl control='radio' name='security_risk_exist' options={cyberRadioOptions}
                                label={lang.security_risk_exist} />
                            <FormikControl control='radio' name='previous_projects_tech_docs' options={previousProjectoptions}
                                label={lang.previous_projects_tech_docs} />
                            <div className="action-btn">
                                <input type="button" className="button " value='Geri' onClick={() => props.setActiveIndex(props.activeIndex - 1)} />
                                <input type="submit" className="button primary " disabled={!formik.isValid || formik.isSubmitting} value='Tamamla' />
                            </div>
                        </Form>)

                }
            </Formik>
        </div>
    );
};

export default withRouter(InfrastructureForm);