import {mobilePrefix} from './config'


export const mobileNumberFormat = (values) => {
    const { value } = values;
    if (value.length > 1) {
        for (let i = 0; i < mobilePrefix.length; i++) {
            if (value.startsWith(mobilePrefix[i]))
                return true
        }
    }
    else
        return true
}

