import { ErrorMessage, Field } from 'formik';
import React from 'react';
import TextError from './TextError';

import NumberFormat from 'react-number-format';

const Number = props => {
    const { label, name, format, ...rest } = props;
    return (
        <div className={`form-control ${props.formclass ? props.formclass : ''}`}>
            {label && <label htmlFor={name}>{label}</label>}
            <Field>
                {({ form }) => <NumberFormat id={name} name={name}
                        className={`form-input ${props.fieldClass ? props.fieldClass : ''} ${(form.errors[name] && form.touched[name]) ? 'error-border' : ''}`}
                        onValueChange={values => form.setFieldValue(name, values.value)}
                        value={form.values[name]}
                        onBlur={form.handleBlur}
                        format={format} allowEmptyFormatting mask="_"   {...rest} />
                }

            </Field>
            <ErrorMessage name={name} component={TextError} />
        </div>
    )
}

export default Number;