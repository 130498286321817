export const AUTH_USER = 'AUTH_USER';
export const GET_TOKEN = 'GET_TOKEN';
export const IS_LOADING_AUTH = 'IS_LOADING_AUTH';
export const GET_CERTS = 'GET_CERTS';
export const IS_LOADING_CERTS = 'IS_LOADING_CERTS';
export const SET_CERTIFICATE = 'SET_CERTIFICATE';
export const SET_BASIC_INFO = 'SET_BASIC_INFO';
export const SET_ACTIVITIES_INFO = 'SET_ACTIVITIES_INFO';
export const ANNUAL_TURNOVERS_LIST = 'ANNUAL_TURNOVERS_LIST';
export const CERTS_LIST = 'CERTS_LIST';
export const PARTNERS_LIST = 'PARTNERS_LIST';
export const SET_PROJECTS_INFO = 'SET_PROJECTS_INFO';
export const SET_HR_INFO = 'SET_HR_INFO';
export const SET_INFRASTRUCTURE_INFO = 'SET_INFRASTRUCTURE_INFO';
export const IS_REGISTERED = 'IS_REGISTERED';
export const IS_REGISTRAION_LOADING = 'IS_REGISTRAION_LOADING';
export const GET_PROJECTS_LIST = 'GET_PROJECTS_LIST';
export const UPLOADED_FILES = 'UPLOADED_FILES';
export const IS_UPLOAD_FILES = 'IS_UPLOAD_FILES';
export const PROJECT_SCOPES_LIST = 'PROJECT_SCOPES_LIST';
export const PROPOSAL_SENDED = 'PROPOSAL_SENDED';
export const CLEAR_SENDED_PROPOSAL = 'CLEAR_SENDED_PROPOSAL';
export const PROPOSAL_IS_LOADING = 'PROPOSAL_IS_LOADING';
export const GET_PROPOSALS = 'GET_PROPOSALS';
export const SET_PROPOSAL_DATA = 'SET_PROPOSAL_DATA';
export const CLEAR_PROPOSAL_DATA = 'CLEAR_PROPOSAL_DATA';