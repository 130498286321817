import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import authRequired from '../../HOC/authRequired';
import { getCertificates, switchCertificate, logout } from '../../../redux/actions/auth';
import Loader from '../../common/Loader';
import asanLoginLogo from '../../../resources/assets/images/EHIM-3.svg';
import FormikControl from '../../common/formControl/FormikControl';
import { Form, Formik } from 'formik';
import { withRouter } from 'react-router-dom';


const SwitchCertificates = (props) => {
    const { getCertificates, auth, certificates, isLoadingCerts } = props;
    useEffect(() => {
        getCertificates(auth);
    }, [getCertificates, auth])


    if (isLoadingCerts)
        return <Loader />
    let onSubmit = (values) => {
        let certNumber = values.switchCertificate;
        props.switchCertificate(certNumber, props.history.push('/main'));
    }
    let switchCertificateOptions = certificates.map(cert => ({ id: cert.certificateNumber, label: cert.structureData?.structureName }));

    return <div className='asan-login'>
        <div className='asan-login__block'>


            <div className='logo'>
                <img src={asanLoginLogo} alt='' />

            </div>
            <div className="content__form">
                <Formik
                    initialValues={certificates}
                    onSubmit={onSubmit}
                    enableReinitialize
                // validationSchema={validationSchema}
                >
                    {formik => <Form>
                        <FormikControl control='select' name='switchCertificate' formclass='switchCerts'
                            label='Sertifikatı seçin' placeholder='' options={switchCertificateOptions} />
                        <input type="submit" className="button primary switchCerts--btn" disabled={!formik.isValid || formik.isSubmitting} value='Daxil ol' />
                    </Form>}
                </Formik>
            </div>
            <div className='bottom-title'>
                <div>version 0.0.1</div>
                <div><span>&#169;</span> 'Elektron Hökumətin İnkişaf Mərkəzi' Punlik Hüquqi Şəxsi</div>
            </div>
        </div>
    </div>


};

const mapStateToProps = state => {
    return {
        auth: state.auth.authenticated,
        certificates: state.auth.certificates,
        isLoadingCerts: state.auth.isLoadingCerts,
    }
}
export default compose(
    authRequired,
    withRouter,
    connect(mapStateToProps, { getCertificates, switchCertificate, logout })
)(SwitchCertificates);