import React from 'react';
import editIcon from '../../../../resources/assets/images/edit.svg';
import { Link } from 'react-router-dom';
import Loader from '../../../common/Loader';
import Details from '../../../common/Details';

const DetailsForm = (props) => {
    if (!props.data)
        return <div className='content'><Loader size='tiny' /></div>
    if (Array.isArray(props.data))
        if (props.data.length === 0)
            return <div className='content'><Loader size='tiny' /></div>

    return (
        <div className='content'>
            <span className='flex'><h3>{props.title}</h3>
                <Link to={`/main/edit/${props.index}`} className='flex'> <img src={editIcon} alt="edit" className='pointer' /></Link></span>

            <Details data={props.data} {...props} />
        </div>
    );
};

export default DetailsForm;