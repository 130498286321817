import React from 'react'
import { Field, ErrorMessage } from 'formik'
import TextError from './TextError'

function CheckboxGroup (props) {
  const { label, name, options, ...rest } = props
  return (
    <div>
      <label className='block-title'>{label}</label>
      <div className='form-control form-control--inline'>
      <Field name={name}>
        {({ field }) => {
          return options.map(option => {
            return (
              <React.Fragment key={option.key}>
                <input
                  type='checkbox'
                  id={option.id}
                  {...field}
                  {...rest}
                  value={option.value}
                  className='checkbox'
                  checked={field.value.includes(option.value)}
                />
                <label htmlFor={option.id}  className='checkbox-label'>{option.key}</label>
              </React.Fragment>
            )
          })
        }}
      </Field>
      </div>
      <ErrorMessage component={TextError} name={name} />
    </div>
  )
}

export default CheckboxGroup