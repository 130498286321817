import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import OrganizationInformation from './content/OrganizationInformation';
import Project from './content/Project';
import Projects from './content/Projects';
import FormProposal from './content/proposal/FormProposal';
import Proposals from './content/proposal/Proposals';
import RegisterCompany from '../registration/RegisterContainer';
import NotFound from '../../common/NotFound';

const ContentContainer = (props) => {
    return (
        <Switch>
            <Route path='/main' exact component={OrganizationInformation} />
            <Route path='/main/edit/:id' render={() =>
                <div style={{ marginTop: '-50px' }}><RegisterCompany /></div>} />
            <Route path='/main/proposals' exact component={Proposals} />
            <Route path='/main/proposals/new/:id?' component={FormProposal} />
            <Route path='/main/projects' exact component={Projects} />
            <Route path='/main/projects/:id' component={Project} />
            <Redirect to='/'  />
        </Switch>
    );
};

export default ContentContainer;