import React from 'react';

const FormTab = (props) => {
    return (<>
        <div className='title' >
            <div className='title__text'
                style={props.activeIndex !== props.order ? { opacity: 0.5 }:null}>
                <span className='title__order'>{props.order + 1}</span>{props.title}</div>
        </div>
    </>
    );
};

export default FormTab;