import React from 'react';
import { withRouter } from 'react-router-dom';

const BackButton = (props) => {
    return (
        <div className="backBtn" onClick={() => props.history.goBack()}>
            <div className='arrow-left'></div>
            <span>{props.title}</span>
        </div>
    );
};

export default withRouter(BackButton);