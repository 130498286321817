import { FieldArray } from 'formik';
import React from 'react';
import FormikControl from './FormikControl';

const ArrayFields = (props) => {
    const { name, label, options, initialValues, closeBtn,disabled } = props;
    return (
        <div>

            <div>
                <FieldArray name={name}
                    render={
                        ({ push, remove, form }) => {
                            return form.values[name] ? form.values[name].map((value, index) => <div key={index}>
                                {(!disabled && closeBtn && index > 0 ) ? <div className='closeBtn' onClick={() => remove(index)}></div> : ''}
                                <label htmlFor={name} className='block-title'>{label}</label>
                                <div className='form-inline'>
                                    {options.map((opt, i) => <FormikControl key={i} control={opt.control ? opt.control : 'input'} name={`${name}[${index}][${opt.key}]`}
                                        label={opt.label} placeholder={opt.placeholder} mindate={opt.minDate && value[opt.minDate]} disabled={disabled} className='addressScale'/>
                                    )}
                                    {(!props.disableremove && (form.values[name].length > initialValues?.length)) && <button type='button' className='button primary icon' onClick={() => remove(index)}> - </button>}
                                    {index >= form.values[name].length - 1 && <button type='button' className={`button primary icon ${props.btnclass ? props.btnclass : ''}`}
                                        onClick={() => push(initialValues ? initialValues[0] : '')} disabled={props.disabled}> + {props.btn ? props.btn : ''}</button>}
                                </div>
                            </div>) : ''
                        }
                    }
                />
            </div>
        </div>
    );
};

export default ArrayFields;