import { CLEAR_PROPOSAL_DATA, CLEAR_SENDED_PROPOSAL, GET_PROJECTS_LIST, GET_PROPOSALS, IS_UPLOAD_FILES, PROJECT_SCOPES_LIST, PROPOSAL_IS_LOADING, PROPOSAL_SENDED, SET_PROPOSAL_DATA, UPLOADED_FILES } from "../actions/types";



let initialValues = {
    projectList: [],
    uploadedFiles: [],
    isUploadLoaded: null,
    projectScopesList: [],
    proposalSended: null,
    proposalsLoading: null,
    proposals: [],
    proposalData: null
}
export const proposalReducer = (state = initialValues, action) => {
    switch (action.type) {
        case GET_PROJECTS_LIST:
            return {
                ...state,
                projectList: action.payload
            }
        case UPLOADED_FILES:
            return {
                ...state,
                uploadedFiles: action.payload
            }
        case IS_UPLOAD_FILES:
            return {
                ...state,
                isUploadLoaded: action.isLoading
            }
        case PROJECT_SCOPES_LIST:
            return {
                ...state,
                projectScopesList: action.payload
            }
        case PROPOSAL_SENDED:
            return {
                ...state,
                proposalSended: action.payload
            }
        case CLEAR_SENDED_PROPOSAL:
            return {
                ...state,
                proposalSended: null
            }
        case PROPOSAL_IS_LOADING:
            return {
                ...state,
                proposalsLoading: action.payload
            }
        case GET_PROPOSALS:
            return {
                ...state,
                proposals: action.payload
            }
        case SET_PROPOSAL_DATA:
            return {
                ...state,
                proposalData: action.payload
            }
        case CLEAR_PROPOSAL_DATA:
            return {
                ...state,
                proposalData: null
            }
        default:
            return state;
    }
}