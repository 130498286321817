import React, { useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import * as actions from '../../../redux/actions/registration';
import BasicInfoForm from './BasicInfoForm';
import CompanyActivities from './CompanyActivities';
import FormTab from './FormTab';
import HRForm from './HRForm';
import InfrastructureForm from './InfrastructureForm';
import ProjectsForm from './ProjectsForm';
import lang from '../../../resources/lang/lang.json';

const RegisterContainer = (props) => {
    let specificIndex = parseInt(props.match.params.id);
    const [activeIndex, setActiveIndex] = useState(specificIndex - 1 || 0);
    const { annualTurnoverList, certsList, partnersList } = props;
    let forms = [
        {
            title: lang.BASIC_INFO,
            component: (data) => <BasicInfoForm {...data} />,
            inputValues: props.basicInfo,
            handleSubmit: (values, cb) => props.setBasicInfo(values, cb),
            getData: props.getBasicInfo
        },
        {
            title: lang.COMPANY_ACTIVITIES,
            component: (data) => <CompanyActivities {...data} />,
            inputValues: props.activitiesInfo,
            handleSubmit: (values, cb) => props.setCompanyActivities(values, cb),
            getData: props.getCompanyActivities,
            getLists: props.getActivitiesLists,
            extraData: { annualTurnoverList, certsList, partnersList }
        },
        {
            title: lang.PROJECTS,
            component: (data) => <ProjectsForm {...data} />,
            inputValues: props.projectsInfo,
            handleSubmit: (values, cb) => props.setProjectInfo(values, cb),
            getData: props.getProjectInfo
        },
        {
            title: lang.HUMAN_RESOURCES,
            component: (data) => <HRForm {...data} />,
            inputValues: props.HRInfo,
            handleSubmit: (values, cb) => props.setHRInfo(values, cb),
            getData: props.getHRInfo
        },
        {
            title: lang.CYBER_SECURITY,
            component: (data) => <InfrastructureForm {...data} />,
            inputValues: (props.infrastructureInfo ? {
                ...props.infrastructureInfo,
                security_risk_exist: props.infrastructureInfo.security_risk_exist?.toString(),
                previous_projects_tech_docs: props.infrastructureInfo.previous_projects_tech_docs?.toString()
            } : null),
            handleSubmit: (values, cb) => props.setInfrastructureInfo(values, cb),
            getData: props.getInfrastructureInfo
        }
    ]
    return (
        <div className='content__forms'>
            {
                forms.map((form, index) => {
                    const { title, inputValues, component } = form;
                    return <div key={index}>
                        <FormTab order={index} title={title} activeIndex={activeIndex} />
                        {index === activeIndex && component({ inputValues, setActiveIndex, activeIndex, ...form })}
                    </div>
                })
            }

        </div>
    );
};
let mapsStateToProps = state => {
    return {
        basicInfo: state.registration.basicInfo,
        activitiesInfo: state.registration.activitiesInfo,
        annualTurnoverList: state.registration.annualTurnoverList,
        certsList: state.registration.certsList,
        partnersList: state.registration.partnersList,
        projectsInfo: state.registration.projectsInfo,
        HRInfo: state.registration.HRInfo,
        infrastructureInfo: state.registration.infrastructureInfo
    }
}
export default compose(
    connect(mapsStateToProps, actions),
    withRouter,
)(RegisterContainer);