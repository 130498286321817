import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import * as actions from "../../../../redux/actions/registration";
import lang from '../../../../resources/lang/lang.json';
import DetailsForm from './DetailsForm';


const OrganizationInformation = (props) => {
    const { getBasicInfo, getCompanyActivities, getActivitiesLists,
        getProjectInfo, getHRInfo, getInfrastructureInfo } = props;

    useEffect(() => {
        getBasicInfo();
        getCompanyActivities();
        getActivitiesLists();
        getProjectInfo();
        getHRInfo();
        getInfrastructureInfo();
    }, [getBasicInfo, getCompanyActivities, getActivitiesLists,
        getProjectInfo, getHRInfo, getInfrastructureInfo]);

    return (<>
        <DetailsForm title={lang.BASIC_INFO} data={props.basicInfo} index={1}/>
        <DetailsForm title={lang.COMPANY_ACTIVITIES} data={props.activitiesInfo} index={2}
            annual_turnover_id={props.annualTurnoverList} certificate_type_id={props.certsList} partner_type_id={props.partnersList} />
        <DetailsForm title={lang.PROJECTS} data={props.projectsInfo} index={3}/>
        <DetailsForm title={lang.HUMAN_RESOURCES} data={props.HRInfo} index={4}/>
        <DetailsForm title={lang.CYBER_SECURITY} data={props.infrastructureInfo} radio index={5} />
    </>
    );
};
let mapStateToProps = state => {
    return {
        basicInfo: state.registration.basicInfo,
        activitiesInfo: state.registration.activitiesInfo,
        projectsInfo: state.registration.projectsInfo,
        HRInfo: state.registration.HRInfo,
        annualTurnoverList: state.registration.annualTurnoverList,
        certsList: state.registration.certsList,
        partnersList: state.registration.partnersList,
        infrastructureInfo: state.registration.infrastructureInfo
    }
}
export default connect(mapStateToProps, actions)(OrganizationInformation);