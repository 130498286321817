import React from 'react';
import { Link } from 'react-router-dom';
import lang from '../../../../../resources/lang/lang.json';
import infoIcon from '../../../../../resources/assets/images/info.svg';

const ListProposal = (props) => {

    return (
        <div>
            <Link to='/main/proposals/new'>
                <button className='button primary float-right'>
                    <span>+</span> {lang.newProposal}</button>
            </Link>

            <div className='table'>
                <table>
                    <thead  >
                        <tr >
                            <th>{lang.proposalNumber}</th>
                            <th>{lang.projectName}</th>
                            <th>{lang.fullname}</th>
                            <th>{lang.status}</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {props.proposals.map(proposal => {
                            return <tr key={proposal.id}>
                                <td style={{ textAlign: 'center', width: '130px' }}>{proposal.id}</td>
                                <td>{proposal.project_name}</td>
                                <td>{proposal.responsible_person}</td>
                                <td>{proposal.status === 1 ? <span style={{ color: '#00865A' }}>{lang.approved}</span> : proposal.status === 2 ? <span style={{ color: '#a29a00' }}>{lang.finished}</span> : lang.waitingApprove}</td>
                                <td style={{ width: '40px' }} ><Link to={`/main/proposals/new/${proposal.id}`}><img src={infoIcon} alt="info" className='icon' /></Link></td>
                            </tr>

                        })}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default ListProposal;