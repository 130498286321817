import { Zoom } from "react-toastify";

export const mobilePrefix = ['50', '51', '55', '70', '77', '60', '99'];

export const toastError = {
    position: "bottom-left",
    autoClose: 3000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    transition: Zoom,
    progress: undefined,
}