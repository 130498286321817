import React from 'react';
import lang from '../../resources/lang/lang.json';

const Details = (props) => {
    delete props.data.id;
    let { data, ...rest } = props;

    let labels = Object.keys(data);
    let values = Object.values(data);


    let getDataValues = (value, label) => {
        if (Array.isArray(value))
            return value.map((val, i) => <Details key={i} data={val} {...rest} />)
        let selectBox = Object.keys(props).find(obj => obj === label);
        if (selectBox) {
            let selectBoxValue = props[Object.keys(props).find(obj => obj === label)].find(select => select.id === value);
            return selectBoxValue?.label ? selectBoxValue.label : selectBoxValue?.name
        }
        if (value === null || value === '' || value === undefined)
            return ' --- '
        if (props.radio) {
            if (value === 1)
                return 'Hə'
            if (value === 0)
                return 'Yox'
        }
        return value;
    }
    return (
        <div>
            {labels.map((lbl, i) =>
                <div className='items' key={i}>
                    <div className='content__title'>

                        <div className='item'>{lang[lbl]}</div>
                    </div>
                    <div className='item'>{getDataValues(values[i], lbl)}</div>
                </div>)}
        </div>
    );
};

export default Details;