import React from 'react';
import { connect } from 'react-redux';
import lang from '../../../../../resources/lang/lang.json';
import { downloadFile } from '../../../../../redux/actions/proposal';

const ShowUploadedFiles = (props) => {
    return (
        <div>
            <div >
                <label className='proposal-block__title'>{lang.downloadIncomeExpenditure}</label>
                {(props.data && props.data.request_files.length > 0) ? props.data.request_files.map(file =>
                    <div className='uploadedFiles pointer downloadFiles' key={file.id}
                        onClick={() => props.downloadFile(file.filename)}>
                        {file.filename}
                    </div>
                ) : <div className='uploadedFiles'>---</div>}
            </div>
        </div>
    );
};

export default connect(null, { downloadFile })(ShowUploadedFiles);