import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import Loader from '../../../../common/Loader';
import { uploadFiles, clearUploadedFiles } from "../../../../../redux/actions/proposal";
import lang from '../../../../../resources/lang/lang.json';
import uploadIcon from '../../../../../resources/assets/images/upload.svg';

const UploadFiles = (props) => {
    const { clearUploadedFiles } = props;
    useEffect(() => {
        return () => clearUploadedFiles('CLEAER FIES');
    }, [])


    let inputOpenFileRef = React.createRef();
    let showOpenFileDlg = () => {
        inputOpenFileRef.current.click()
    }
    let handleChangeFiles = (e) => {
        var formData = new FormData();
        for (var i = 0; i < e.target.files.length; i++) {
            let file = e.target.files[i];
            formData.append(`files`, file);
        }
        formData.append('files', e.target.files);
        props.uploadFiles(formData);
    }

    if (Array.isArray(props.uploadedFiles))
        props.getUploadedFiles(props.uploadedFiles)

    return (
        <div>
            <label className='proposal-block__title'>{lang.downloadIncomeExpenditure}</label>
            <input ref={inputOpenFileRef} type="file" multiple hidden onChange={handleChangeFiles}
                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" />
            <button type='button' className="button proposal__btn white " onClick={showOpenFileDlg}>{!props.isUploadLoaded ?
                <><img src={uploadIcon} alt="upload" /> {lang.downloadFile}</> : <Loader size='tiny' />}</button>
            {Array.isArray(props.uploadedFiles) ? props.uploadedFiles.map((file, i) =>
                <div key={i} className='uploadedFiles'>{file.originalname}</div>) : <div className='uploadedFiles'>{props.uploadedFiles}</div>}
            <label className='proposal-block__title--red'>{lang.fileValidation}</label>
        </div>
    );
};
let mapStateToProps = state => {
    return {
        uploadedFiles: state.proposal.uploadedFiles,
        isUploadLoaded: state.proposal.isUploadLoaded,
    }
}
export default connect(mapStateToProps, { uploadFiles, clearUploadedFiles })(UploadFiles);