import axios from "axios"
import jwtDecode from "jwt-decode";
import { errorHandler } from "../../common/errorHandler";
import { baseURL } from "./baseUrl";
import { ASAN_LOGIN_CHANGE_CERT, ASAN_LOGIN_CHECK_TOKEN, ASAN_LOGIN_GET_CERTS, ASAN_LOGIN_LOGOUT, ASAN_LOGIN_REDIRECT, ASAN_LOGIN_TOKEN } from "./routers"
import { GET_CERTS, GET_TOKEN, IS_LOADING_AUTH, IS_LOADING_CERTS, SET_CERTIFICATE } from "./types";






export const checkToken = (token) => dispatch => {
    dispatch({ type: IS_LOADING_AUTH, payload: true })
    // dispatch({ type: GET_TOKEN, payload: null })
    axios.get(ASAN_LOGIN_CHECK_TOKEN, {
        headers: {
            Authorization: token
        }
    })
        .then(() => {
            //set auth token to header of backends requests
            baseURL.defaults.headers.Authorization = token;
            dispatch({ type: SET_CERTIFICATE, payload: jwtDecode(token).main.person })
            dispatch({ type: GET_TOKEN, payload: token })
            //loader should stop ony in succcess
            dispatch({ type: IS_LOADING_AUTH, payload: false })
        })
        .catch(err => {

            if (err.response?.status === 401) {
                dispatch(getToken())
            }
        })
}
export const getToken = () => dispatch => {
    axios.get(ASAN_LOGIN_TOKEN, {
        withCredentials: true
    })
        .then(response => {
            const { token } = response.data.data;
            dispatch({ type: GET_TOKEN, payload: token })
            //set auth token to header of backends requests
            baseURL.defaults.headers.Authorization = token;

            localStorage.setItem('token', token)
            let decoded = jwtDecode(token);
            dispatch({ type: SET_CERTIFICATE, payload: decoded.main.person })
        })
        .finally(() => dispatch({ type: IS_LOADING_AUTH, payload: false }))
        .catch(err => {
            dispatch({ type: GET_TOKEN, payload: null })
            localStorage.clear(); 
        })
}


export const asanLogin = () => dispatch => {
    window.location.replace(ASAN_LOGIN_REDIRECT)
}


export const getCertificates = (token) => dispatch => {
    dispatch({ type: IS_LOADING_CERTS, payload: true })
    axios.get(ASAN_LOGIN_GET_CERTS, {
        headers: {
            Authorization: token
        }
    })
        .then(response => {
            const certs = response.data.data.availableCertificates;
            dispatch({
                type: GET_CERTS,
                payload: certs
            })
        })
        .catch(err => errorHandler(err))
        .finally(() => dispatch({ type: IS_LOADING_CERTS, payload: false }))
}

export const switchCertificate = (certificateNumber, redirect=function(){}) => (dispatch, getState) => {
    let token = getState().auth.authenticated;
    axios.put(ASAN_LOGIN_CHANGE_CERT, { certificateNumber }, { headers: { Authorization: token } })
        .then(response => {
            const { token } = response.data.data;
            dispatch({
                type: GET_TOKEN,
                payload: token
            })
            //set auth token to header of backends requests
            baseURL.defaults.headers.Authorization = token;

            localStorage.setItem('token', token)
            let decoded = jwtDecode(token);
            dispatch({ type: SET_CERTIFICATE, payload: decoded.main.person });
            redirect();
        })
        .catch(err => errorHandler(err))

}

export const logout = () => dispatch => {
    localStorage.clear();
    window.location.replace(ASAN_LOGIN_LOGOUT)
}