import ContentContainer from "./ContentContainer";
import NavBar from "./NavBar"





const AccountContainer = (props) => {
    return (
        <div className='account'>
            <div className='account__navbar'><NavBar /></div>
            <div className='account__content'><ContentContainer /></div>
        </div>
    );
}

export default AccountContainer;